import React, { Component } from 'react'
import { graphql, StaticQuery, Link } from 'gatsby';
import facebookIcon from '../images/footer-facebook.png';
import instagramIcon from '../images/footer-instagram.png';
import youtubeIcon from '../images/footer-youtube.png';

class Footer extends Component {

  render() {
  return <StaticQuery query={graphql`
  {
        wordpressPage(slug: { eq: "home" }){
            acf {
              footer_heading
              facebook
              instagram
              youtube
              copyright_text
              copyright_address
              copyright_phone
              copyright_email
            }
        } 
        
        allWordpressWpApiMenusMenusItems(filter: {
          slug:{
            eq:"footer-menu"
          }
        }){
          edges{
            node{
              name
              items{
                title
                object_slug
                url
              }
            }
          }
      }

    }
  `} render={props => (
      <footer >

        <div className="footer-section">
          <div className="frame">
            <div className="heading" data-aos="fade-right">{ props.wordpressPage.acf.footer_heading }</div>
            <div className="social-icons">
              <div className="social-icon" data-aos="fade-up">
                <a href={props.wordpressPage.acf.facebook} target="_blank">
                  <img src={ facebookIcon } alt="facebook icon" />
                </a>
              </div>
              <div className="social-icon" data-aos="fade-up" data-aos-delay="300">
                <a href={props.wordpressPage.acf.instagram} target="_blank">
                  <img src={ instagramIcon } alt="instagram icon" />
                </a>
              </div>
              <div className="social-icon" data-aos="fade-up" data-aos-delay="600">
                <a href={props.wordpressPage.acf.youtube} target="_blank">
                  <img src={ youtubeIcon } alt="youtube icon" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright-section">
          <div className="frame">
            <div className="copyright-left">
              <div className="copyright-text">&copy; {props.wordpressPage.acf.copyright_text}</div>
              <div className="footer-menu">
                {props.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(item => (
                    <div key={item.title} className="menu-item ">
                        <Link to={item.object_slug} key={item.title} dangerouslySetInnerHTML={{ __html: item.title }}></Link>
                    </div>
                ))}
              </div>
            </div>
            <div className="copyright-right">
                <div className="copyright-address">{props.wordpressPage.acf.copyright_address}</div>
                <div className="copyright-phone"><a href={"tel:"+ props.wordpressPage.acf.copyright_phone}>{props.wordpressPage.acf.copyright_phone}</a></div>
                <div className="copyright-email"><a href={"mailto:"+ props.wordpressPage.acf.copyright_email}>{props.wordpressPage.acf.copyright_email}</a></div>
            </div>
          </div>
        </div>

      </footer>
  )} />
  }
}

export default Footer


import React, {Component} from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import loader from "../images/enquiry-loader.gif";

class Popup extends Component {

    constructor(props) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    state = {
        isPopUpOpen: false
    };
    
    handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
          [name]: value,
        })
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    componentDidUpdate() {
        if (this.state.isPopUpOpen !== this.props.isPopUpOpen) {
            this.setState({
                isPopUpOpen: this.props.isPopUpOpen
            });
        }
    }
    

    handleSubmit = event => {
        event.preventDefault();
        document.getElementById('enquiry-button').className = "add-spinner";

        if(this.state.firstName && this.state.lastName && this.state.emailAddress) {
            var xhr = new XMLHttpRequest()

            xhr.addEventListener('load', (data) => {
                let res             = JSON.parse(data.target.response);
                
                if(res.status == "success") {

                    document.getElementById('success').style.opacity    = 1;
                    document.getElementById('success').style.zIndex     = 100000;
                    document.getElementById('enquiry-button').classList.remove("add-spinner");
                    setTimeout(() => {
                        document.getElementById('success').style.opacity    = 0;
                        document.getElementById('success').style.zIndex     = -1;
                    }, 5000)
                
                    if(this.state.isPopUpOpen) {
                        this.props.closePopup();
                    }


                } else {
                    document.getElementById('error').style.opacity    = 1;
                    document.getElementById('error').style.zIndex     = 100000;
                    document.getElementById('enquiry-button').classList.remove("add-spinner");
                    setTimeout(() => {
                        document.getElementById('error').style.opacity    = 0;
                        document.getElementById('error').style.zIndex     = -1;
                    }, 5000)
                }
                
            })

                // open the request with the verb and the url
                xhr.open('POST', 'https://api.yc360.com.au/v1/leads/connect')
                xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
                xhr.send(JSON.stringify({
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    emailAddress: this.state.emailAddress,
                    mobile: this.state.phone,
                }))

            
        } else {
            document.getElementById('error').style.opacity    = 1;
            document.getElementById('error').style.zIndex     = 100000;
            document.getElementById('enquiry-button').classList.remove("add-spinner");
            setTimeout(() => {
                document.getElementById('error').style.opacity    = 0;
                document.getElementById('error').style.zIndex     = -1;
            }, 5000)
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if(this.state.isPopUpOpen) {
                this.props.closePopup();
            }
        }
    }

    render() {
    return <StaticQuery query={graphql`
    {

        wordpressPage(slug: { eq: "home" }){
            acf {
                cwup_heading
                cwup_subheading
                cwup_first_name
                cwup_last_name
                cwup_email
                cwup_phone
                cwup_submit_button
                cwup_image {
                    source_url
                }
                cwup_success_1
                cwup_success_2
                cwup_error_1
                cwup_error_2
            }
        }

    }
    `} render={props => (
        <div>
            <div className="cwu-popup fade-in" style={{display: this.state.isPopUpOpen ? 'flex' : 'none' }}>
                <div className="cwu-popup-inner" ref={this.setWrapperRef}>
                    <div className="left">
                        <div className="left-image" style={{backgroundImage: 'url(' + props.wordpressPage.acf.cwup_image.source_url + ')'}} ></div>
                    </div>
                    <div className="right">
                        <div className="heading">{ props.wordpressPage.acf.cwup_heading }</div>
                        <div className="subheading">{ props.wordpressPage.acf.cwup_subheading }</div>
                        <form className="contact-form" onSubmit={this.handleSubmit}>
                            <input type="text" name="firstName" value={this.state.firstName} onChange={this.handleInputChange} placeholder={ props.wordpressPage.acf.cwup_first_name } />
                            <input type="text" name="lastName" value={this.state.lastName} onChange={this.handleInputChange} placeholder={ props.wordpressPage.acf.cwup_last_name } />
                            <input type="text" name="emailAddress" value={this.state.emailAddress} onChange={this.handleInputChange} placeholder={ props.wordpressPage.acf.cwup_email } />
                            <input type="text" name="phone" value={this.state.phone} onChange={this.handleInputChange} placeholder={ props.wordpressPage.acf.cwup_phone } />
                            <div className="btn">
                                <button type="submit" id="enquiry-button">
                                    <span className="btn-text">{ props.wordpressPage.acf.cwup_submit_button }</span>
                                    <img src={loader} />
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="popup-success" id="success"><strong>{ props.wordpressPage.acf.cwup_success_1 }</strong><br/>{ props.wordpressPage.acf.cwup_success_2 }</div>
            <div className="popup-error" id="error"><strong>{ props.wordpressPage.acf.cwup_error_1 }</strong><br/>{ props.wordpressPage.acf.cwup_error_2 }</div> 
        </div>
    ) } />
    }
};

export default Popup;
import React, { Component } from "react"
import { graphql, StaticQuery, Link } from 'gatsby';
import logo from "../images/logo.png";
import logoWhite from "../images/logo-white.png";
import Popup from "./cwu-popup"
const isSSR = typeof window === "undefined";

class Header extends Component {

  constructor(props) {
    super(props);
  }

  state = {
    isMenuOpened: false,
    isPopUpOpen: false
  };

  closePopup() {
    this.setState({
        isPopUpOpen: false
    }); 
  }

  openPopup = (e) => {
    e.preventDefault();
    this.setState( () => {
      return { isPopUpOpen: true, isMenuOpened: false, isFixedMenuOpened: false };
    });
  };

  openMenu = () => {
    if(!this.state.isMenuOpened) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    this.setState( ( prevState ) => {
      return { isMenuOpened: !prevState.isMenuOpened };
    });
  }

  closeMenu = () => {
    document.body.style.overflow = 'unset';
    this.setState( () => {
      return { isMenuOpened: false, isFixedMenuOpened: false };
    });
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  render() {
  return <StaticQuery query={graphql`
  {
      allWordpressWpApiMenusMenusItems(filter: {
        slug:{
          eq:"main-menu"
        }
      }){
        edges{
          node{
            name
            items{
              title
              object_slug
              url
            }
          }
        }
    }

  }
  `} render={props => (
    <div>
      {!isSSR && (
       <div> 
        <header >
          <div className={"frame " + ( window.location.pathname === '/about' ? 'header-transparent' : '') }>
            <Link to="/">
              {window.location.pathname === '/about' ? (
                  <img className="logo desktop-white" src={logoWhite} alt="logo" />
                    ) : (
                  <img className="logo desktop" src={logo} alt="logo" />
              )}
            </Link>
            <div className="menu-mobile">
            
              <div className={"header-menu "  + ( this.state.isMenuOpened ? 'menu-opened' : '')} >
                {props.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(item => (
                    <div key={item.title} className="menu-item ">
                        {( item.object_slug === 'connect-with-us' ? 
                        (
                          <a onClick = {(event) => this.openPopup(event)} dangerouslySetInnerHTML={{ __html: item.title }}></a>
                        ) : (
                          <Link className={( item.object_slug === 'home' ? window.location.pathname === '/' ? 'active' : '' : window.location.pathname === '/' + item.object_slug ? 'active' : '')} to={ item.object_slug === 'home' ? "/" : `/${item.object_slug}`} key={item.title} dangerouslySetInnerHTML={{ __html: item.title }}></Link>
                        )
                        )}
                        
                    </div>
                ))}
              </div>

              <div className={ "mobilebutton " + ( this.state.isMenuOpened ? 'menu-opened' : '')}>
                <a className="menu-btn menu-btn1" onClick={this.openMenu}>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </a>
              </div>   

              <img className="logo" src={logo} alt="logo" />           

          </div>

          </div>
        </header>

        <Popup isPopUpOpen = { this.state.isPopUpOpen } closePopup={() => this.closePopup()} />

      </div>
      )}
      </div>
  )} />
  }
};

export default Header

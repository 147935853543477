import "../css/fonts.css"
import "../css/style.scss"
import "../css/responsive.scss"
import React from 'react'
import Header from "../components/header"
import Footer from "../components/footer"
import Helmet from "react-helmet"
import { withPrefix } from "gatsby" 

const DefaultTemplate = ({ children }) => (
    <div>
      <Helmet>
       {/* <link rel="icon" href={favicon} /> */}
        <script src={withPrefix('studio137.js')} type="text/javascript" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-168096381-1"></script>
        <script>{`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-168096381-1');
        `}</script>
      </Helmet>
      <Header />
      { children }
      <Footer />
    </div>
  )
  
export default DefaultTemplate
  
   
